<template>
  <div class="M3">
    <van-image :src="imgUrl" />
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage } from "vant";
import { getTSpecificById } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    VanImage,
    copyrightIp,
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  mounted() {
    this.getTSpecific();
  },
  methods: {
    getTSpecific() {
      let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      getTSpecificById({ tspecificId }).then((res) => {
        this.imgUrl = res.data.data.tspecificTempItems[0]?.itemValue || "";
        getJSSDK({
          title: res.data.data.tspecific.specificName || "中智薪税",
          link: this.$route.fullPath,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.M3 {
  width: 100%;
  min-height: 100vh;
  .van-image {
    width: 100%;
    ::v-deep img {
      width: 100%;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}
</style>
